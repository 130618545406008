define('levit-intranet/components/user-info/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend( /* withWSMixin,*/{
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    modelLoader: Ember.inject.service(),

    right: false,
    login: null,
    password: null,

    // socketName: 'reminders',

    notifications: new Ember.A(),
    updated: null,

    messageHandler: function messageHandler(event) {
      var data = JSON.parse(event.data);
      if (data.action !== undefined) {
        if (data.action === 'refresh') {
          this._get_reminders();
        } else if (data.action === 'delete') {
          var model = data.model;
          var store = this.get('store');
          data.ids.forEach(function (id) {
            var record = store.peekRecord(model, id);
            if (!Ember.isEmpty(record) && record.get('isDeleted') !== true) {
              record.deleteRecord();
            }
          });
          this.set('updated', new Date());
        }
      } else {
        this.get('store').pushPayload(data);
        this.set('updated', new Date());
      }
    },
    _get_reminders: function _get_reminders() {
      var _this = this;

      return this.get('store').query('crm/event', { has_reminder: true }).then(function () {
        _this.set('updated', new Date());
      });
    },
    initReminders: function initReminders() {
      var _this2 = this;

      this.get('modelLoader').ensure_model('crm', 'event').then(function () {
        // this should not be necessary
        // fix in ember-cli-dynamic-model
        _this2.get('modelLoader').ensure_model('sale', 'product'); /* .then(() => {
                                                                   Ember.run.later(this, () => {
                                                                   this._get_reminders().then(() => {
                                                                   this.connectSocket();
                                                                   });
                                                                   }, 1000);
                                                                   }); */
      });
    },
    didInsertElement: function didInsertElement() {
      if (this.get('session.isAuthenticated')) {
        this.initReminders();
      }
    },


    onAuthenticate: Ember.observer('session.isAuthenticated', 'session.currentUser', function () {
      if (this.get('session.isAuthenticated')) {
        this.initReminders();
      }
    }),

    updateNotifications: Ember.observer('updated', function () {
      var events = this.get('store').peekAll('crm/event');
      var updated = this.get('updated');
      var session = this.get('session');
      var me = session.get('currentUser');
      var rv = events.filter(function (event) {
        if (event.get('isDeleted') === true) {
          return false;
        }
        var has_reminder = event.get('has_reminder');
        var reminder = new Date(event.get('reminder'));
        var state = event.get('state');
        if (has_reminder === true && reminder <= updated && state !== 'closed') {
          return parseInt(event.get('next_assigned_to.id')) === parseInt(me.id) || event.get('next_assigned_group.name') === me.group;
        }
        return false;
      });
      this.set('notifications', rv);
    }),

    close: function close(record) {
      var _this3 = this;

      record.close().then(function () {
        _this3.set('updated', new Date());
      });
    }
  });
});