define('levit-intranet/models/base/accounting/tax', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    name: (0, _attr.default)('string'),

    name_fi_fi: (0, _attr.default)('string'),

    apply_from: (0, _attr.default)('number'),

    apply_up_to: (0, _attr.default)('number'),

    tax_type: (0, _attr.default)('string'),

    value: (0, _attr.default)('number'),

    suitable_for_client_documents: (0, _attr.default)('boolean'),

    __str__: (0, _attr.default)('string'),

    modifiers: (0, _relationships.hasMany)('accounting/taxmodifier', {
      async: true,
      inverse: 'tax'
    }),

    account: (0, _relationships.belongsTo)('accounting/account', {
      async: true,
      inverse: null
    })

  });
});