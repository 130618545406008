define('levit-intranet/models/base/accounting/taxmodifier', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    name: (0, _attr.default)('string'),

    name_fi_fi: (0, _attr.default)('string'),

    value: (0, _attr.default)('number'),

    dont_apply: (0, _attr.default)('boolean'),

    conditions: (0, _attr.default)(),

    position: (0, _attr.default)('number'),

    __str__: (0, _attr.default)('string'),

    tax: (0, _relationships.belongsTo)('accounting/tax', {
      async: true,
      inverse: 'modifiers'
    })

  });
});